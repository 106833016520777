import { IonCard, IonCol, IonItem, IonRow, IonSkeletonText, IonText } from '@ionic/react';
import { Comment } from '../Interfaces/Interfaces'
import React, { useEffect, useState } from 'react'
import { VoteButtons } from './VoteButtons'
import Cookies from 'universal-cookie';
import CommentBox from './CommentBox';
import RenderComment from './Comment'

interface CommentsRendererProps {
    pointID: string,
    commentPrompt?: Function,
    votePrompt?: Function,
}

const Comments = ({ pointID, commentPrompt, votePrompt}: CommentsRendererProps) => {
    const [comments, setComments] = useState<Array<Comment>>();
    const [oldPointID, setOldPointId] = useState<string>()

    // Used to place a comment in the array
    function place(array: Array<Comment>, item: Comment) {

        if (item["Parent ID"]) {
            let found = false;
            array.forEach(element => {
                if (found) return
                if (element.ID === item["Parent ID"]) {

                    element.Children.push(item)
                    found = true
                }
                else if (element.Children) {

                    found = place(element.Children, item)
                }
            })
            return found
        }
        else {

            array.push(item)
            return true
        }
    }
    let sum = 0
    function count(arr: Array<any>) {
        arr.forEach((element) => {
            sum += 1
            if (element.Children.length > 0) {
                count(element.Children)
            }
        })
    }

    let fetchData = new FormData();
    fetchData.append("point id", pointID);
    fetchData.append("jwt", new Cookies().get("jwt"))
    useEffect(() => {

        if (pointID && pointID !== oldPointID) {
            setOldPointId(pointID)
            fetch('https://app.deaddev.com/final/v0.1/point/comments', {
                method: 'POST',
                mode: 'cors',
                body: fetchData

            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson["success"]) {
                        console.log(responseJson["comments"])
                        setComments(responseJson["comments"])
                    }
                }
                );

        }
    })

    if (!comments) {
        return <IonRow>
            {[...Array(6)].map((e, i) => <IonCard style={{ width: "100%", padding: 12 }}>
                <IonSkeletonText animated style={{ width: '110px', height: '20px' }} />
                <IonSkeletonText animated style={{ width: 'fill', height: '80px' }} />
            </IonCard>)}
        </IonRow>
    }
    else {
        let nestedComments = new Array<Comment>()

        let placedID = Array<string>();

        comments.forEach((element) => {
            element.Children = new Array<Comment>();
        })

        while (sum < comments.length) {

            comments.forEach((element) => {

                if (!placedID.includes(element.ID)) {
                    let result = place(nestedComments, element)

                    sum = 0

                    count(nestedComments)

                    if (result) {
                        placedID.push(element.ID)
                    }
                }
            })
        }


        return nestedComments.length > 0 ? <IonRow style={{ marginBottom: 20 }}><IonCol>
            {nestedComments.map((element) => {
                return <RenderComment comment={element} pointID={pointID} commentPrompt={commentPrompt} votePrompt={votePrompt}/>
            })}
        </IonCol></IonRow> : <></>
    }


}; export default Comments;