import {
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
  } from '@ionic/react';
  
  import { BrowserRouter, Redirect, useLocation, useParams, withRouter } from 'react-router-dom';
  import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, personCircle, pin, trashOutline, trashSharp, walk, warning, warningOutline, warningSharp, wifi, wine } from 'ionicons/icons';
  import './Auth.css';
  import React, { FormEvent, useEffect, useState } from 'react';
  import Cookies from 'universal-cookie';
  import { render } from 'react-dom';
  import { Swiper, SwiperSlide } from "swiper/react";

  const Recovery: React.FC = () => 
  {
    const { pin,UserId , code } = useParams<{ pin: string; UserId: string; code: string;}>();
    const [password, setPassword] = useState<string>(code);
    const [submitNewPassword, setsubmitNewPassword] = useState<string>();
    const [resultColor, setResultColor] = useState<string>();
    const colors = {
        "error": "#FF6666",
        "normal": "#DDDDDD"
    };
    
    const cookies = new Cookies();
    if(!pin || !UserId) {
        window.location.href = '/Forgot'; //relative to domain
    }

    const HandleNewPassword = (e?: FormEvent) => {
        e?.preventDefault()
        setResultColor(colors["normal"]);
        setsubmitNewPassword("connecting to the server");
        
        const NewPasswordData  = new FormData();
       if (password !== undefined && pin !== undefined && UserId !== undefined) 
        {
            NewPasswordData.append('pin', pin);
            NewPasswordData.append('password', password);
            NewPasswordData.append('UserId', UserId);


        }
        
        // Send data to the backend via POST
        fetch('https://app.deaddev.com/final/v0.1/auth/recover', {
            method: 'POST', 
            mode: 'cors', 
            body: NewPasswordData // body data type must match "Content-Type" header
            
        })
        .then(response => response.json())
        .then(responseJson => {
            if(responseJson["success"]) 
            {
                setResultColor(colors["normal"]);
                setsubmitNewPassword("Password updated, Redirecting ...");
                const cookies = new Cookies();
                cookies.set('jwt', responseJson["jwt"], { path: '/' });  
                setTimeout(() => 
                {
                    window.location.href = '/Login'; //relative to domain
                }, 500);
            }
            else 
            {
                setResultColor(colors["error"]);
                setsubmitNewPassword(responseJson["reason"]);
            }
        })
        .catch(error =>{
            setResultColor(colors["error"]);
            setsubmitNewPassword("Password update failed, network error")
        })
    }
    
    

    return <IonPage>
        {/* Not important header stuff */}
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Waddup</IonTitle>
      </IonToolbar>
    </IonHeader>
        <IonContent class="ion-text-center">
        <IonCard id = "NewPasswordCard">
        <IonHeader>
            <IonToolbar>
                <IonTitle>Password Recovery</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonGrid style={{padding: 24}}>
            
            <IonRow>
                <IonCol>
                    <IonIcon
                    style={{ fontSize: "70px"}}
                    icon={personCircle}
                    />
                </IonCol>
            </IonRow>
            
            <IonRow>
                <IonCol>
                    <p>Enter your new password </p>
                </IonCol>
            </IonRow>
            <IonRow>

                <IonCol>
                    <p style={{ fontSize: "medium", color: resultColor}}
                    id="SubmitResult">
                    { submitNewPassword }
                    </p>
                </IonCol>
            </IonRow>
            <form onSubmit={HandleNewPassword}>
        
            <IonRow>
                <IonCol>
                    <IonItem fill="outline">
                    <IonLabel position="fixed" ion-text-wrap>UserId</IonLabel>
                    <IonText ion-text-wrap style={{opacity: 0.6}}> {UserId}
                    </IonText>
                    </IonItem>
                </IonCol>
            </IonRow>
             
            <IonRow>
                <IonCol>
                <IonItem fill="outline">
                        <IonLabel position="floating">New Password</IonLabel>
                        <IonInput
                            type="password"
                            minlength = {8}
                            debounce={8}
                            required
                            value={password}
                            onIonChange={(e) => setPassword(e.detail.value!)}
                            >
                        </IonInput>
                      </IonItem>
                </IonCol>
            </IonRow>            
            <IonRow>
                <IonCol>
                    <p style={{ fontSize: "small" }}>
                    Didn't receive a verification code? <a href="/Signup">Send again</a>
                    </p>
                    <IonButton type="submit" expand="block">
                    Change Password
                    </IonButton>
                    {/* This is a hack to fix the form not submitting on enter */}
                    <input type="submit" style={{visibility: 'hidden'}} />

                </IonCol>
            </IonRow>
            </form>
        </IonGrid>
        </IonCard>
        </IonContent>
    </IonPage>
  }
  export default withRouter(Recovery)