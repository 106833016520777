import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import MainPage from './pages/Main page';
import Login from './auth/Login';
import Verify from './auth/Verification';
import Signup from './auth/Signup';
import PointInfoSkeleton from './components/PointInfoSkeleton'
import Permissions from './pages/Permissions';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Whiteboard from './Whiteboard';
import Forgot from './auth/Forgot';
import NewPassword from './auth/Recovery';
import Recovery from './auth/Recovery';
import Profile from './pages/Profile';
import Privacy from './components/Privacy';
import Logout from './auth/Logout';



setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/Home/" />
            </Route>
            <Route path="/Home/" exact={true}>
              <Menu />
              <MainPage />
            </Route>
            <Route path="/Home/point/:point_id/:comment_id" exact={true}>
              <Menu />
              <MainPage />
            </Route>
            <Route path="/Home/point/:point_id/" exact={true}>
              <Menu />
              <MainPage />
            </Route>
            <Route path="/Login/" exact={true}>
              <Menu />
              <Login />
            </Route>
            <Route path="/Logout/">
              <Logout />
            </Route>
            <Route path="/Verify/:email/:id/" exact={true}>
              <Verify />
            </Route>
            <Route path="/Verify/:email/:id/:code" exact={true}>
              <Verify />
            </Route>
            <Route path="/Signup" exact={true}>
              <Menu />
              <Signup />
            </Route>
            <Route path="/Forgot" exact={true}>
              <Menu />
              <Forgot />
            </Route>
            <Route path="/Recovery/:UserId/:pin" exact={true}>
              <Recovery />
            </Route>
            <Route path="/Recovery" exact={true}>
              <Recovery />
            </Route>
            <Route path={"/User/:customid"} exact={true}>
              <Menu />
              <Profile />
            </Route>
            <Route path={"/User/"} exact>
              <Redirect to="/Home" />
            </Route>
            <Route path={"/Permissions/"} exact>
              <Menu />
              <Permissions />
            </Route>
            <Route path={"/Privacy/"} exact>
              <Menu />
              <Privacy />
            </Route>
            <Redirect exact from="/Verify/" to="/Signup" />
            <Redirect exact from="/Verify/:email" to="/Signup" />

          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
