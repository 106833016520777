import {
    createAnimation,
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { checkmark, personCircle, reloadOutline } from 'ionicons/icons';
import './Logout.css';
import React, { FormEvent, useState } from 'react';
import Cookies from 'universal-cookie';


const Logout: React.FC<any> = () => {

    const [done, SetDone] = useState(false)

    new Cookies().remove("jwt")
    new Cookies().remove("user id")

    setTimeout(() => {
        SetDone(true)
        setTimeout(() => 
        {
            window.location.href = "/Home"

        }, 200)
    }, 1000)

    return <IonPage>
        <IonContent class="ion-text-center">
            <IonCard id="Logoutcard" >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Logging out</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonRow>
                    <IonCol style={{ padding: '80px' }}>
                        {done ?
                            <IonIcon icon={checkmark} style={{ fontSize: 72 }} color="success"></IonIcon>
                            :
                            <IonSpinner name="circles" style={{ transform: "scale(3)" }} />
                        }
                    </IonCol>
                </IonRow>
            </IonCard>
        </IonContent>
    </IonPage>


}
export default withRouter(Logout)