import { IonButton, IonCard, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonRow, IonSkeletonText, IonText, IonTitle, IonToolbar } from "@ionic/react"
import { useState, useEffect } from "react"
import Cookies from "universal-cookie"
import { PointInfo as PointInfoInterface } from '../Interfaces/Interfaces'
import { Media } from "../Interfaces/Interfaces"
import VideoPlayer from "./VideoPlayer"

interface PointInfoProps {
    point_id: number | undefined,
}

const UserPointInfo = ({ point_id }: PointInfoProps) => {

        const [pointInfo, setPointInfo] = useState<PointInfoInterface>()
        const [pointsArray, setPointsArray] = useState<Array<number>>(new Array<number>())
        const [pointID, setPointID] = useState<number>(-1)

        {/* Fetches the information of a point from the api */ }

        useEffect(() => {
            if (point_id && pointID !== point_id) {
                setPointID(point_id)
                const formData = new FormData();
                formData.append("point id", point_id.toString());
                    
               fetch('https://app.deaddev.com/final/v0.1/point', {
                    method: 'POST',
                    mode: 'cors',
                    body: formData

                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson["success"]) {
                            console.log("fetch success")
                            setPointInfo(responseJson["data"])
                            console.log(responseJson)

                        }
                    });
            }

        });

        if (pointInfo) {
            return <>
            <IonCard>

            {pointInfo.Title === null ? <></> : (
                <IonHeader>
                        <IonToolbar>
                                <IonTitle slot="start">
                                    {pointInfo.Title}
                                </IonTitle>
                            </IonToolbar>
                    </IonHeader>)}

                {pointInfo["Media"].length === 0 ? null : (
                    <IonRow style=
                        {{
                            maxHeight: 230,
                            overflowX: "hidden",
                            overflowY: "hidden"
                        }}>
                            
                        <IonCol>
                            <IonItem >
                                <IonContent scrollY={false} scrollX={true} class="ion-text-nowrap" style=
                                    {{
                                        height: pointInfo["Media"].length === 0 ? null : 220,
                                        "--background": "transparent"
                                    }}>
                                    {
                                        pointInfo["Media"].map((media: Media, index: number) => {
                                            console.log(media);
                                            return !media["Is Preview"] ? <img style=
                                            {{
                                                height: 200,
                                                width: "fit-content",
                                                    display: "inline-block",
                                                    padding: 10,
                                                    backgroundColor: "#00000000"
                                                }} src={"https://final.deaddev.com/media/" + media["File name"]}></img>
                                                : <VideoPlayer media={media} />
                                        })}
                                </IonContent>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                )}
                {pointInfo.Description === null ? <></> : (
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonText>
                                    {pointInfo.Description}
                                </IonText>
                            </IonItem>
                        </IonCol>
                    </IonRow>)}
                    <IonFooter>
                        <IonToolbar>
                            <IonButton expand="full" onClick={() => {window.open('/Home/Point/'+pointID)}}fill="clear">
                                Open point
                            </IonButton>
                        </IonToolbar>
                    </IonFooter>
        </IonCard>

            </>
        }
        else {
            return <>
                <IonRow style=
                    {{
                        maxHeight: 230,
                        overflowX: "hidden",
                        overflowY: "hidden"
                    }}>
                    <IonCol>
                        <IonItem >
                            <IonContent scrollY={false} scrollX={true} class="ion-text-nowrap" style=
                                {{
                                    height: 220,
                                    "--background": "transparent"
                                }}>

                                <IonSkeletonText animated style={{ width: '130px', height: '200px', display: 'inline-block', margin: 10 }} />
                                <IonSkeletonText animated style={{ width: '380px', height: '200px', display: 'inline-block', margin: 10 }} />
                                <IonSkeletonText animated style={{ width: '130px', height: '200px', display: 'inline-block', margin: 10 }} />
                                <IonSkeletonText animated style={{ width: '380px', height: '200px', display: 'inline-block', margin: 10 }} />
                            </IonContent>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonSkeletonText animated style={{ width: '100%', height: '80px' }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonSkeletonText animated style={{ width: '90px', height: '20px' }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
            </>
        }
    }

    export default UserPointInfo;