import {
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';

import { useParams, withRouter } from 'react-router-dom';
import { personCircle } from 'ionicons/icons';
import './Profile.css';
import React, { FormEvent, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { strictEqual } from 'assert';
import { UserProfile, UserProfile as UserProfileInterface } from '../Interfaces/Interfaces'

//imports for the pointInfo
import { Media } from "../Interfaces/Interfaces";
import { PointInfo as PointInfoInterface } from '../Interfaces/Interfaces'
import { LatLng, Map } from 'leaflet'
import VideoPlayer from "../components/VideoPlayer";
import UserPointInfo from '../components/UserPointInfo';

interface UserProfileProps {
    user_Id: string | undefined
}


const Profile: React.FC = () => {
    const { customid } = useParams<{ customid: string }>();

    if (!customid) {
        window.location.href = '/Login'; //relative to domain
    }
    const [userPoints, SetUserPoints] = useState<Array<number>>();
    const [userInfo, setUserInfo] = useState<UserProfile>()
    const [userID, setUserID] = useState<string>();

    const UserProfile = ({ user_Id }: UserProfileProps) => {
        useEffect(() => {
            if (user_Id && userID !== user_Id) {
                setUserID(user_Id)
                const formData = new FormData();
                formData.append("custom user id", user_Id.toString());
                formData.append("jwt", new Cookies().get("jwt"))
                fetch('https://app.deaddev.com/final/v0.1/user', {
                    method: 'POST',
                    mode: 'cors',
                    body: formData

                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson["success"]) {
                            console.log(responseJson["data"])
                            setUserInfo(responseJson["data"])
                        }
                    });

                const pointsFormData = new FormData();
                pointsFormData.append("user_id", user_Id)
                pointsFormData.append("page", "0")
                fetch('https://app.deaddev.com/final/v0.1/posts', {
                    method: 'POST',
                    mode: 'cors',
                    body: pointsFormData

                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson["success"]) {
                            console.log(responseJson)
                            SetUserPoints(responseJson["points"])
                        }
                    });


            }

        });

        if (userInfo) {
            return <>

                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">User ID</IonLabel>
                            <IonText style={{ opacity: 0.6 }}> {userInfo['Custom user ID']}
                            </IonText>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">Public Name</IonLabel>
                            <IonText style={{ opacity: 0.6 }}> {userInfo['Public name']}
                            </IonText>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">SignUp Date</IonLabel>
                            <IonText style={{ opacity: 0.6 }}> {userInfo['Signup date']}
                            </IonText>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">Reputation</IonLabel>
                            <IonText style={{ opacity: 0.6 }}> {userInfo.Reputation}
                            </IonText>
                        </IonItem>
                    </IonCol>
                </IonRow>

            </>
        }
        else {
            return <>
                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">User ID</IonLabel>
                            <IonSkeletonText animated style={{ width: '100%', height: '20px' }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">Public Name</IonLabel>
                            <IonSkeletonText animated style={{ width: '100%', height: '20px' }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">SignUp Date</IonLabel>
                            <IonSkeletonText animated style={{ width: '100%', height: '20px' }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem fill="outline">
                            <IonLabel position="fixed">Reputation</IonLabel>
                            <IonSkeletonText animated style={{ width: '100%', height: '20px' }} />
                        </IonItem>
                    </IonCol>
                </IonRow>

            </>
        }
    }
    return <IonPage>
        {/* Not important header stuff */}
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>Waddup</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent class="ion-text-center">
            <IonCard id="ProfileCard">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>User Profile</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid style={{ padding: 24 }}>

                    <IonRow>
                        <IonCol>
                            <IonIcon
                                style={{ fontSize: "70px" }}
                                icon={personCircle}
                            />
                        </IonCol>
                    </IonRow>
                    <UserProfile user_Id={customid}  ></UserProfile> {//whait for the api}
                    }
                </IonGrid>
            </IonCard>
            <IonCard id="ProfileCard"  >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>User's Posts</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {
                    userPoints ?
                        userPoints.map((point_id: number) => {
                            return <UserPointInfo point_id={point_id} ></UserPointInfo>
                        })
                        : <>The else</>
                }
            </IonCard>
        </IonContent>
    </IonPage>
}
export default withRouter(Profile)
