import {
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';

import { withRouter } from 'react-router-dom';
import { personCircle } from 'ionicons/icons';
import './Auth.css';
import React, { FormEvent, useState } from 'react';
import Cookies from 'universal-cookie';
import { strictEqual } from 'assert';


const Signup: React.FC<any> = () => {
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [userId, setUserId] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [publicName, setPublicName] = useState<string>();
    const [signUpResult, setSignUpResult] = useState<string>();
    const [resultColor, setResultColor] = useState<string>();
    const colors = {
        "error": "#FF6666",
        "normal": "#DDDDDD"
    };


    const HandleSignup = (e: FormEvent) => {
        e.preventDefault()
        setResultColor(colors["normal"]);
        setSignUpResult("Signing you up...");

        const formData = new FormData();

        if (!email || !password || !userId || !firstName || !lastName || !publicName) {
            return false
        }
        formData.append('email', email);
        formData.append('password', password);
        formData.append('custom user id', userId);
        formData.append('first name', firstName);
        formData.append('last name', lastName);
        formData.append('public name', publicName);

        // function to check if userId has white space
        function hasSpace(value: string) {
            return value.indexOf(' ') >= 0;
        }
        var whiteSpace = hasSpace(userId);
        if (whiteSpace == true) {
            setResultColor(colors["error"]);
            setSignUpResult("Your user ID can't have white spaces");
        } else {

            // Send data to the backend via POST
            fetch('https://app.deaddev.com/final/v0.1/auth/signup', {
                method: 'POST',
                mode: 'cors',
                body: formData // body data type must match "Content-Type" header

            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson["success"]) {
                        setResultColor(colors["normal"]);
                        setSignUpResult("Signed up, redirecting...");
                        const cookies = new Cookies();
                        cookies.set('jwt', responseJson["jwt"], { path: '/' });
                        setTimeout(() => {

                            window.location.href = '/Verify/' + email + '/' + userId; //relative to domain
                        }, 500);
                    }
                    else {
                        setResultColor(colors["error"]);
                        setSignUpResult(responseJson["reason"]);
                    }
                })
                .catch(error => {
                    setResultColor(colors["error"]);
                    setSignUpResult("Login failed, network error")
                })
        }
    }

    return <IonPage>
        {/* Not important header stuff */}
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>Waddup</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent class="ion-text-center">
            <IonCard id="SignupCard">

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Signup</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonRow>
                    <IonCol>
                        <IonIcon
                            style={{ fontSize: "70px" }}
                            icon={personCircle}
                        />
                    </IonCol>
                </IonRow>
                <IonGrid style={{ padding: 24 }}>
                    <IonRow>
                        <IonCol>
                            <p style={{ fontSize: "medium", color: resultColor }}
                                id="SignupResult">
                                {signUpResult}
                            </p>
                        </IonCol>
                    </IonRow>
                    <form onSubmit={HandleSignup}>
                        <IonRow>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="floating">Email</IonLabel>
                                    <IonInput
                                        type="email"
                                        inputMode="email"
                                        debounce={8}
                                        required
                                        value={email}
                                        onIonChange={(e) => setEmail(e.detail.value!)}
                                    >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="floating"> Password</IonLabel>
                                    <IonInput
                                        type="password"
                                        minlength={8}
                                        debounce={8}
                                        required
                                        value={password}
                                        onIonChange={(e) => setPassword(e.detail.value!)}
                                    >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="floating"> User Id</IonLabel>
                                    <IonInput
                                        type="text"
                                        minlength={4}
                                        debounce={8}
                                        required
                                        value={userId}
                                        onIonChange={(e) => setUserId(e.detail.value!)}
                                    >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="floating"> Public Name</IonLabel>
                                    <IonInput
                                        type="text"
                                        minlength={4}
                                        debounce={8}
                                        required
                                        value={publicName}
                                        onIonChange={(e) => setPublicName(e.detail.value!)}
                                    >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="floating"> First Name</IonLabel>
                                    <IonInput
                                        type="text"
                                        minlength={3}
                                        debounce={8}
                                        required
                                        value={firstName}
                                        onIonChange={(e) => setFirstName(e.detail.value!)}
                                    >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="floating"> Last Name</IonLabel>
                                    <IonInput
                                        type="text"
                                        minlength={3}
                                        debounce={8}
                                        required
                                        value={lastName}
                                        onIonChange={(e) => setLastName(e.detail.value!)}
                                    >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p style={{ fontSize: "small" }}>
                                    By clicking Sign Up you agree to our <a href="/Privacy/">Policy</a>
                                </p>
                                {/* This is a hack to fix the form not submitting on enter */}
                                <input type="submit" style={{ height: 0, border: 0, display: "none" }} />
                                <IonButton type="submit" expand="block">
                                    Sign Up
                                </IonButton>
                                <p style={{ fontSize: "medium" }}>
                                    <a href="/Forgot">Forgot password</a>
                                </p>
                            </IonCol>
                        </IonRow>
                    </form>
                </IonGrid>
            </IonCard>
        </IonContent>
    </IonPage>


}
export default withRouter(Signup)