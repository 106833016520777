import { IonButton, IonCard, IonCol, IonIcon, IonInput, IonItem, IonList, IonPopover, IonRow, IonText, useIonToast } from "@ionic/react";
import React, { BaseSyntheticEvent, useState } from "react";
import CommentBox from "./CommentBox";
import { VoteButtons } from "./VoteButtons";
import { Comment } from '../Interfaces/Interfaces';
import { ellipsisVertical } from "ionicons/icons";
import Cookies from "universal-cookie";

interface renderCommentInterface {
    pointID: string
    comment: Comment
    commentPrompt?: Function,
    votePrompt?: Function
}

const RenderComment = ({ comment, commentPrompt, pointID, votePrompt }: renderCommentInterface) => {

    const [isCommenting, SetIsCommenting] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [disabled, SetDisabled] = useState(false);

    const [Toast] = useIonToast();

    const ToggleCommenting = () => {
        if (commentPrompt) {
            commentPrompt()
            return
        }
        SetIsCommenting(!isCommenting);

    }

    const ToggleEdit = () => {
        setIsEditing(!isEditing);
    }

    const EditComment = (e: BaseSyntheticEvent) => {
        e.preventDefault()
        comment["Comment"] = e.target.comment.value
        let formData = new FormData(e.target)
        formData.append("comment id", comment.ID)
        formData.append("jwt", new Cookies().get("jwt"))
        SetDisabled(true)
        fetch('https://app.deaddev.com/final/v0.1/edit/point/comment/', {
            method: 'POST',
            mode: 'cors',
            body: formData // body data type must match "Content-Type" header

        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson["success"]) {
                    SetDisabled(false)
                    setIsEditing(false);
                }
                else {
                    Toast({ message: responseJson["reason"], duration: 1200, color: "danger" })
                    SetDisabled(false)
                }
            })
            .catch(error => {
                Toast({ message: "Something went wrong!", duration: 1200, color: "danger" })
                SetDisabled(false)
                comment["Comment"] = e.target.comment.value
            });

    }

    return <IonCard style={{ width: "fill", padding: 12 }}>
        <IonRow>
            <IonCol>
                <IonItem>
                    <IonText slot="start">{comment["Public name"]}</IonText>
                    {comment["Can edit"] ? 
                    <>
                        <IonButton id={"Comment-edit-" + comment.ID} slot="end" fill="clear">
                            <IonIcon icon={ellipsisVertical}></IonIcon>
                        </IonButton>
                        <IonPopover trigger={"Comment-edit-" + comment.ID} dismissOnSelect>
                            <IonList>
                                <IonItem button onClick={ToggleEdit}>
                                    Edit
                                </IonItem>
                                { /* <IonItem button>
                                    Delete
                                </IonItem> */ }
                            </IonList>
                        </IonPopover>
                    </>
                        : null}
                </IonItem>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <form onSubmit={EditComment}>
                    <IonItem fill={isEditing ? "outline" : undefined}>
                        {isEditing ? <>
                            <IonInput slot="start" disabled={disabled} value={comment["Comment"]} name="comment"></IonInput><IonButton disabled={disabled} slot="end" type="submit">Save</IonButton></>
                            :
                            <IonText>{comment["Comment"]}</IonText>
                        }
                    </IonItem>
                </form>
            </IonCol>
        </IonRow>

        <VoteButtons {...{
            reputation: comment.Reputation, voteLink: "point/comment/vote",
            shareLink: "https://final.deaddev.com/Home/point/" + pointID + "/comment/" + comment.ID, elementKey: "comment id", elementValue: comment.ID, voted: comment.Vote,
            toggleCommenting: ToggleCommenting,
            prompt: votePrompt
        }} />
        {isCommenting ? <CommentBox commentLink="/v0.1/point/comment" idParam="point id" id={pointID.toString()} parentId={comment.ID} toggleCommenting={ToggleCommenting} /> : null}
        <IonRow>
            <IonCol>
                {comment.Children.length > 0 ? comment.Children.map((element) => { return <RenderComment comment={element} pointID={pointID} commentPrompt={commentPrompt} /> }) : <></>}
            </IonCol>
        </IonRow>
    </IonCard>

};
export default RenderComment;