import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonicSwiper, IonImg, IonInput, IonItem, IonLabel, IonMenuButton, IonModal, IonPage, IonPopover, IonRow, IonSkeletonText, IonSlide, IonSlides, IonText, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonToast } from '@ionic/react';
import { add, addCircle, checkmark, locate, close, addCircleSharp, pin, grid, folder, folderOpen, cloudUpload, cloudUploadOutline, caretDownOutline, caretUpOutline, arrowUp, arrowDown } from 'ionicons/icons'
import { MapContainer, TileLayer, useMap, Marker, Popup, useMapEvents, LayersControl } from 'react-leaflet'
import './Main page.css';
import React, { BaseSyntheticEvent, FormEvent, useEffect, useState } from 'react';
import { LatLng, LocationEventHandlerFn, LatLngExpression, marker, map, popup, Icon, IconOptions, point, icon, Point, Layer, Map } from 'leaflet';
import { isAbsolute } from 'path';
import { Media, Resolution, Comment, PointInfo as PointInfoInterface, UserPermissions } from '../Interfaces/Interfaces';
import VideoPlayer from '../components/VideoPlayer'
import Cookies from 'universal-cookie';
import Comments from '../components/Comments'
import { VoteButtons } from '../components/VoteButtons';
import { PointInfo } from '../components/PointInfo';
import { useParams } from 'react-router';

const MainPage: React.FC = () => {

  const { point_id, comment } = useParams<{ point_id: string; comment: string; }>();

  // Used to force the map to load correctly
  function AntiMapBug() {
    const map = useMapEvents({
    })
    setTimeout(() => {
      map.invalidateSize();
    }, 250);

    return null
  }

  const [permissions, setPermissions] = useState<UserPermissions>();

  // The geolocation of the user
  const [userPosition, setUserPosition] = useState<LatLng>()
  // The center of the map the user is looking at
  const [lastFetchCenter, setLastFetchCenter] = useState<LatLng>(new LatLng(33.8735578, 35.863749));
  const [mapCenter, setMapCenter] = useState<LatLng>(lastFetchCenter);
  // Used to show the modal and mofidy it's title
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>();
  // Used to store the poing info that will be shown to the user
  const [pointId, setPointID] = useState<number>();
  const cookies = new Cookies();
  const [loggedIn, setLoggedIn] = useState(cookies.get("jwt") !== undefined)

  const [alert] = useIonAlert();

  const [goToPointFromUrl, setGoToPointFromUrl] = useState(true);

  const [gotPermissions, setGotPermissions] = useState(false);

  const UpdatePermissions = () => {
    if (gotPermissions) {
      return
    }
    setGotPermissions(true)
    let formData = new FormData();
    formData.append("jwt", cookies.get("jwt"))

    fetch('https://app.deaddev.com/final/v0.1/auth/permissions', {
      method: 'POST',
      mode: 'cors',
      body: formData // body data type must match "Content-Type" header

    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson["success"]) {
          let permisisonsArray: Array<String> = responseJson["data"]
          let newPermissions: UserPermissions = {};
          permisisonsArray.forEach((value: String) => {
            console.log(value)
            // I know this is ugly, but this is the best thing I have come up with
            let key: keyof UserPermissions = value.toString();
            newPermissions[key] = true
            console.log(newPermissions)
          })
          setPermissions(newPermissions)
        }
        else {

        }
      });
  }

  const [loadMarkers, setLoadMarkers] = useState(true);

  useEffect(() => {
    if (point_id && goToPointFromUrl) {
      getPointInfo(Number(point_id), "Getting point info")
      setGoToPointFromUrl(false);
    }
    UpdatePermissions()

  })



  // The steps the user goes through to add a point
  enum PointAddingSteps {
    nothing,
    choosingLocationOnMap,
    addingDetails
  }
  // The states of the modal to know what to render
  enum ModalStates {
    closed,
    showingPoint,
    showingComment,
    addingPoint,
    addingComment,
  }

  // Used to track which step the user is at when adding a point
  const [pointAddingStatus, setPointAddingStatus] = useState<PointAddingSteps>(PointAddingSteps.nothing);
  // If the user can confirm the point position (Affected by the zoom level)
  const [pointIsValid, SetPointIsValid] = useState<boolean>(false);
  // Show the user that they need to zoom in to be able to add a point
  const [showZoomHint, setShowZoomHint] = useState<boolean>(false);
  // Current zoom of the map, used for point adding validation
  const [mapZoom, setMapZoom] = useState<number>(1);
  // Used to keep track of the current state of the modal
  const [modalState, setModalState] = useState<ModalStates>(ModalStates.closed);
  // Used to know if the user wants to go to their location when we get it
  const [goToUser, setGoToUser] = useState<boolean>(false);

  const LoginPrompt = (action: string) => {
    alert({
      cssClass: 'my-css',
      header: 'Not logged in',
      message: 'You need to be logged in to ' + action,
      buttons: [
        'Cancel',
        { text: 'Login', handler: (d) => { window.location.href = "/Login" } },
      ],
    })
  }

  const NotAllowedPrompt = (action: string) => {
    alert({
      cssClass: 'my-css',
      header: 'Not allowed',
      message: 'You need to ' + action,
      buttons: [
        'Cancel',
        { text: 'More', handler: (d) => { window.location.href = "/Permissions" } },
      ],
    })
  }

  // Used to set the map state
  const MapFetcher = () => {
    setMap(useMap());
    const map = useMapEvents({
      // Store the location but do not go to it unless the user wants to
      locationfound(e) {
        if (goToUser) {
          setTimeout(() => {
            map.flyTo(e.latlng, 15)
            setGoToUser(false);
          }, 500);

        }
        // Keep track of the user position, and set last fetch for point rendering
        setUserPosition(e.latlng);
        setLastFetchCenter(e.latlng);
      },
      // Only locate after the user interacted with the map to avoid chrome yelling at us
      mousedown() {
        if (userPosition === undefined) {
          map.locate();
        }
      }
    });
    return <></>
  }
  // Called when the user presses on the + button
  // TODO: only "enable" the button when the user is login
  // Can be done by checking the jwt cookie
  function StartAddingPoint() {
    if (pointAddingStatus === PointAddingSteps.nothing) {
      // Zoom in
      map?.flyTo(map.getCenter(), 18);
      if (modalState !== ModalStates.closed) {
        setModalState(ModalStates.closed);
        setShowModal(false);
        setModalTitle("New Point");
      }
      setPointAddingStatus(PointAddingSteps.choosingLocationOnMap);
    }
  }

  // Show the user their location if they ask for it
  function ShowLocation() {
    setGoToUser(true);
    if (userPosition !== undefined) {
      map?.flyTo(userPosition, 15)
    }
    else {
      // Try and get the user geolocation
      map?.locate();
    }

    // Only let the user add a point if zoomed in enough
    if (mapZoom > 17) {
      SetPointIsValid(true);
    }
    else {
      SetPointIsValid(false);
    }
  }

  // when the user tries to confirm the point location, deny it if it is too zomed out
  function ConfirmNewPointLocation() {
    if (pointIsValid) {
      setModalState(ModalStates.addingPoint);
      setShowModal(true);
      setModalTitle("New Point");
    }
    else {
      // Tell them why they cannot add a point
      setShowZoomHint(true);
    }

  }

  // Reset the states when the user cancels adding a point
  function CancelAddingPoint() {
    setPointAddingStatus(PointAddingSteps.nothing);
    if (modalState === ModalStates.addingPoint) {
      setShowModal(false);
      setModalState(ModalStates.closed);
    }
  }



  // This is used to parse the basic point information from the fetch request
  // To get variables from the fetch result, add the fields here
  interface Point {
    "ID": number,
    "Title": string,
    "Lat": number,
    "Lng": number,
    "File Name": string
  }

  // The markers that are fetched dynamically
  const [markers, setMarkers] = useState<Array<Point>>();

  // Default value for markers is an empty array
  if (markers === undefined) {
    setMarkers(new Array<Point>());
  }

  // Used to fetch new markers
  function UpdateMarkers() {
    // Stores the data that will be sent to the api to request the points
    const formData = new FormData();
    const map = useMapEvents(
      {
        moveend() {
          let newMapCenter = map.getCenter();
          if (newMapCenter === undefined) {
            console.error("Could not find map center!");
            return null;
          }
          if (lastFetchCenter === undefined) {
            console.error("Could not find the old map center!")
            return null;
          }

          // Make sure when only send a new request to the api when the user has moved far enough
          if (map.distance(lastFetchCenter, newMapCenter) > ((19 - map.getZoom()) ** 2) * 1000 || loadMarkers) {
            setLoadMarkers(false);
            setLastFetchCenter(newMapCenter);
            formData.append("lat", newMapCenter.lat.toString());
            formData.append("lng", newMapCenter.lng.toString());
            fetch('https://app.deaddev.com/final/v0.1/region', {
              method: 'POST',
              mode: 'cors',
              body: formData

            })
              .then(response => response.json())
              .then(responseJson => {
                if (responseJson["success"]) {
                  // set the markers to be rendered

                  // This fixes the wrong markers with images moving
                  setMarkers(undefined);

                  setMarkers(responseJson["points"]);

                }
              });

          };
        },
      });
    return null;
  }

  const getPointInfo = (point_id: number, point_title: string) => {
    if (modalState !== ModalStates.closed && modalState !== ModalStates.showingPoint) {
      return null
    }
    setModalState(ModalStates.showingPoint);
    setPointID(point_id);
    setModalTitle(point_title);
    setShowModal(true);

  }


  // Possible media types for upload, used to know whether to preview using an img / video tag
  enum MediaTypes {
    image,
    video
  }
  // Used to store the preview media information
  interface FilePreview {
    data: string,
    comments: string,
    type: MediaTypes
  }
  // The files to be previewed
  const [uploadFiles, setUploadFiles] = useState<Array<FilePreview>>(Array());
  // Called on file selection
  const PreviewMedia = (e: BaseSyntheticEvent) => {
    // Turn chosen media to an array
    let filesArray: Array<File> = Array.from(e.target.files);

    // Prepare an array to store the parsing result
    let result = new Array<FilePreview>();

    // Parse the media
    filesArray.forEach((file: File) => {

      var reader = new FileReader();

      reader.onload = (e) => {
        if (e.target?.result !== undefined && e.target?.result !== null) {
          if (e.target.result.toString().match(":video/")) {
            let fileResult: FilePreview = {
              data: e.target?.result.toString(), type: MediaTypes.video,
              comments: e.target?.result.toString()
            };
            result.push(fileResult)
          }
          else if (e.target.result.toString().match(":image/")) {
            let fileResult: FilePreview = {
              data: e.target?.result.toString(), type: MediaTypes.image,
              comments: e.target?.result.toString()
            };
            result.push(fileResult)
          }
          // If all of the medias have been loaded, preview them
          if (result.length === filesArray.length)
            setUploadFiles(result)
        }
      }
      // Starts the media loading
      reader.readAsDataURL(file)
    });

  }
  // Called on upload form submit
  const UploadNewPoint = (e: BaseSyntheticEvent) => {
    e.preventDefault()
    // Get the form data
    let formData = new FormData(e.target);
    // I don't know why, but I am going to adding the input field values one by one
    // Do not question the elevated one
    // formData.append("jwt", e.target.elements.jwt.value)
    formData.append("lat", e.target.elements.lat.value)
    formData.append("lng", e.target.elements.lng.value)
    /* formData.append("title", e.target.elements.title.value)
    formData.append("description", e.target.elements.description.value)
    formData.append("files[]", e.target.elements["files[]"])*/
    setIsUploading(true);
    setUploadingStatus("Uploading")
    setUploadstatusIcon(cloudUpload)
    setUploadStatusIconColor("")
    setShowModal(false);
    setUploadStatusIconClass("SmoothFloat");
    setModalState(ModalStates.closed);
    // Send data to the backend via POST
    fetch('https://app.deaddev.com/final/v0.1/post/point', {
      method: 'POST',
      mode: 'cors',
      body: formData // body data type must match "Content-Type" header

    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        setUploadStatusIconClass("");
        // If uploaded successfully, tell the user
        if (responseJson["success"]) {
          setUploadingStatus("Done!")
          setUploadstatusIcon(checkmark)
          setUploadStatusIconColor("success")
          setTimeout(() => {
            setIsUploading(false);
          }, 5000)
        }
        // If uploaded failed, tell the user
        // TODO: Add log in prompt
        else {
          setUploadingStatus("Upload Failed!")
          setUploadstatusIcon(close)
          setUploadStatusIconColor("danger")
          setTimeout(() => {
            setIsUploading(false);
          }, 5000)
        }
      });
  }

  // Variables for the new point form
  const [newPointTitle, setNewPointTitle] = useState<string>();
  const [newPointDesc, setNewPointDesc] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadingStatus, setUploadingStatus] = useState<string>("");
  // Used to change the message shown to the user
  const [uploadStatusIcon, setUploadstatusIcon] = useState<string>(cloudUpload);
  // Used to change the icon shown to the user
  const [uploadStatusIconColor, setUploadStatusIconColor] = useState<string>();
  // Used to change the animation of the upload icon
  const [uploadStatusIconClass, setUploadStatusIconClass] = useState<string>("");

  const [map, setMap] = useState<Map>();

  const VotePrompt = () => {
    return loggedIn ?
      permissions?.Vote ?
        undefined
        :
        () => { NotAllowedPrompt("have an account older than 7 days and with more than 200 reputation to be able to vote") }
      :
      () => { LoginPrompt("vote") }
  }

  const CommentPrompt = () => {
    return loggedIn ?
      permissions?.Comment ?
        undefined
        :
        () => { NotAllowedPrompt("have an account older than 7 days and with more than 200 reputation to be able to comment") }
      :
      () => { LoginPrompt("comment") }
  }

  const CenterFetcher = () => {
    const map = useMapEvents({
      moveend: () => {
        if (modalState === ModalStates.addingPoint) {
          setMapCenter(map?.getCenter())
        }
      }
    })
    return <></>
  }

  const Controls = () => {

    const map = useMapEvents(
      {
        zoom: () => {
          let zoomRes = map.getZoom() > 17;
          if (zoomRes != pointIsValid) {
            SetPointIsValid(zoomRes)
          }
        }
      }
    );


    {/* I moved the controls here to stop them from fliquering */ }
    return <>{
      pointAddingStatus === PointAddingSteps.nothing ?
        (
          <IonButton
            onClick=
            {
              loggedIn ?
                permissions && permissions['Post point'] ?
                  StartAddingPoint :
                  () => { NotAllowedPrompt("have an account older than 7 days and with more than 200 points of reputation to be able to post a point") } :
                () => { LoginPrompt("to post a point") }
            } class="MapOverlay" shape="round" color="light" style={{
              width: 50,
              height: 50,
              position: "absolute",
              opacity: !loggedIn || (permissions && !permissions['Post point']) ? 0.4 : 1,
              right: 20, bottom: 105,
            }}>
            <IonIcon icon={add} style={{
              position: "absolute",
              width: 30,
              height: 30
            }} color="dark" />
          </IonButton>

        ) : pointAddingStatus === PointAddingSteps.choosingLocationOnMap ?
          (
            <>
              {/* If the user is choosing a location on a map, showing the confirm and cancel buttons*/}

              {/* Confirm Button */}
              <IonButton onClick={ConfirmNewPointLocation} class="MapOverlay" shape="round" color="light"
                id="confirm-point-location-button"
                style={{
                  opacity: pointIsValid ? 1 : 0.4,
                  width: 50,
                  height: 50,
                  position: "absolute",
                  right: 20, bottom: 180,
                }}>
                {
                  pointIsValid ? (null) :
                    (
                      <IonPopover isOpen={showZoomHint} onDidDismiss={() => { setShowZoomHint(false) }} trigger="confirm-point-location-button" side="left"
                        arrow={true} showBackdrop={false} translucent={true} dismissOnSelect={true} alignment="center">
                        <IonRow>
                          <IonCol>
                            Zoom in to be able to set a point
                          </IonCol>
                        </IonRow>
                      </IonPopover>
                    )
                }
                <IonIcon icon={checkmark} style={{
                  position: "absolute",
                  width: 30,
                  height: 30
                }} color="dark" />
              </IonButton>

              {/* Cancel Button */}
              <IonButton onClick={CancelAddingPoint} class="MapOverlay" shape="round" color="light" style={{
                width: 50,
                height: 50,
                position: "absolute",
                right: 20, bottom: 105,
              }}>
                <IonIcon icon={close} style={{
                  position: "absolute",
                  width: 30,
                  height: 30
                }} color="dark" />
              </IonButton>
            </>
          ) : pointAddingStatus === PointAddingSteps.addingDetails ?
            (
              null // Don't need to do something special while the user is adding details
            ) : (
              null
            )
    }
      {/* Show the pin when the user has zoomed in enough */}
      {
        pointIsValid && pointAddingStatus === PointAddingSteps.choosingLocationOnMap ?
          (
            <IonIcon class="MapOverlay" id="NewPointPin" icon={pin} style={{
              position: "absolute",
              width: 50,
              height: 50,
            }} color="black" />
          ) : (
            null
          )
      }

      {/* The show location button */}
      <IonButton onClick={ShowLocation} class="MapOverlay" shape="round" color="light" style={{
        width: 50,
        height: 50,
        position: "absolute",
        right: 20, bottom: 30,

      }}>
        <IonIcon icon={locate} style={{
          position: "absolute",
          width: 30,
          height: 30

        }} color="dark" />

      </IonButton></>
  }

  return (
    <IonPage>
      {/* Not important header stuff */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Waddup</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonModal
        isOpen={showModal}
        initialBreakpoint={0.2}
        breakpoints={[0.2, 1]}
        backdropDismiss={false}
        backdropBreakpoint={0.3}
        style={{}}
        onIonModalDidDismiss={() => setShowModal(false)}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{modalTitle}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => { setShowModal(false); setModalState(ModalStates.closed) }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>

        </IonHeader>

        {/* Point info obviously */}
        {modalState === ModalStates.showingPoint ? <IonContent>
          <IonRow>
            <IonCol>
              <IonCard>
                {/* TODO: Fix voting permissions prompt */}
                <PointInfo {...{
                  point_id: pointId, setModalTitle: setModalTitle, map: map,
                  votePrompt: VotePrompt(),
                  commentPrompt: CommentPrompt()
                }} />
              </IonCard>
            </IonCol>
          </IonRow>
          {pointId ? <Comments {...{ pointID: pointId?.toString() }}
            votePrompt={VotePrompt()}
            commentPrompt={VotePrompt()} /> : <></>}</IonContent> : <></>

        }

        { /* New point form */}
        {modalState === ModalStates.addingPoint ? <form onSubmit={UploadNewPoint}><IonGrid>
          <input type="hidden" name="jwt" value={cookies.get("jwt")} />

          <IonRow>
            <IonCol class="CoordinateInput">
              <IonItem>
                <IonLabel position="stacked">Lat</IonLabel>
                <IonInput name="lat" disabled={true} value={mapCenter?.lat}> </IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Lng</IonLabel>
                <IonInput name="lng" disabled={true} value={mapCenter?.lng}> </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Title</IonLabel>
                <IonInput key="NewPointTitle" debounce={8} required name="title" minlength={5} maxlength={30} enterkeyhint="next" value={newPointTitle} onIonChange={(e) => setNewPointTitle(e.detail.value!)} placeholder="Required"></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="fixed" >Description</IonLabel>
                <IonTextarea name="description" rows={1} placeholder="Optional" enterkeyhint="next" value={newPointDesc} onIonChange={(e) => setNewPointDesc(e.detail.value!)} autoGrow={true}></IonTextarea>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Media &nbsp;&nbsp;&nbsp;&nbsp;<IonText color="medium">Required</IonText> </IonLabel>
                <IonButton slot="end" size="default">
                  <label htmlFor="MediaUpload">
                    <IonIcon icon={folderOpen} style={{ fontSize: 20 }}></IonIcon>
                  </label>
                </IonButton>
                <input id="MediaUpload" name="files[]" type="file" style={{ display: "none" }} accept=".mp4, .jpeg, .jpg" onChange={PreviewMedia} multiple required></input>
              </IonItem>
            </IonCol>
          </IonRow>
          {
            uploadFiles.length === 0 ? null :
              <IonRow style=
                {{
                  maxHeight: 200,
                  overflowX: "scroll",
                  overflowY: "hidden"
                }}>
                <IonCol>
                  <IonItem >
                    <IonContent scrollY={false} scrollX={true} class="ion-text-nowrap" style=
                      {{
                        height: uploadFiles.length === 0 ? null : 220,
                        "--background": "transparent"
                      }}>
                      {uploadFiles.length === 0 ?
                        (
                          null
                        ) : uploadFiles.map((previewFile) => {
                          return previewFile.type === MediaTypes.image ? <img style=
                            {{
                              height: 200,
                              width: "fit-content",
                              display: "inline-block",
                              padding: 10,
                              backgroundColor: "#00000000"
                            }} src={previewFile.data}></img>
                            : previewFile.type === MediaTypes.video ?
                              <video style=
                                {{
                                  height: 200,
                                  width: "fit-content",
                                  display: "inline-block",
                                  padding: 10,
                                  backgroundColor: "#00000000"
                                }} src={previewFile.data} controls></video>
                              : null
                        })
                      }
                    </IonContent>
                  </IonItem>
                </IonCol>
              </IonRow>
          }
          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">Post</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid></form> : null}

      </IonModal>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Waddup</IonTitle>
          </IonToolbar>
        </IonHeader>

        <MapContainer key="Map" center={lastFetchCenter} zoom={8} scrollWheelZoom={true} id="Map" >
          {/* Used to fetch the map center for the new point */}
          <CenterFetcher />

          {/* Used to fetch the map */}
          <MapFetcher />
          <TileLayer
            attribution='&copy; <a href="https://api.mapbox.com/copyright">mapbox</a> contributors'
            url='https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGVhZGRldiIsImEiOiJja2x3dmVuN2YwczFqMndtdzN6d3R2N2N1In0.1068z2rX-6kyfMUrUecs-A'
          />

          <Controls />


          { // Render the markers
            markers === undefined ? null :
              markers.map((point: Point) => {

                return point["File Name"] === null ? (
                  <Marker position={[point.Lat, point.Lng]}
                    eventHandlers={{
                      click() {
                        getPointInfo(point.ID, point.Title)
                      }
                    }}
                  >

                  </Marker>) :
                  <Marker icon={new Icon<IconOptions>({
                    iconUrl: "https://final.deaddev.com/media/" + point["File Name"],
                    iconSize: [35, 35],
                  })}
                    position={[point.Lat, point.Lng]}
                    eventHandlers={{
                      click() {
                        getPointInfo(point.ID, point.Title)
                      }
                    }}>

                  </Marker>
              })
          }

          { // Render the user location
            userPosition === undefined ? null :
              <Marker icon={new Icon<IconOptions>({
                // TODO: Replace this icon
                iconUrl: "https://icons-for-free.com/download-icon-my+location+24px-131987943372958995_512.png",
                iconSize: [40, 40],


              })} position={userPosition}>
                {/* I removed the close buton because it was causing me a redirect issue*/}
                <Popup closeButton={false} closeOnClick={true} >You are Here!</Popup>
              </Marker>
          }

          {/* Used to fetch the markers from the api */}
          <UpdateMarkers />

          {/* Used to fix the map not loading correctly bug */}
          <AntiMapBug />

        </MapContainer>
        {/* Uploading toast */}
        <IonItem style={{
          position: "fixed",
          bottom: isUploading ? 50 : -200,
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex: 1000,
          borderRadius: 10,
          transition: "bottom 1s"
        }}>
          <IonText>{uploadingStatus}</IonText>
          <IonIcon icon={uploadStatusIcon} slot="end" class={uploadStatusIconClass} color={uploadStatusIconColor}></IonIcon>
        </IonItem>

      </IonContent>
    </IonPage >
  );

};

export default MainPage;
