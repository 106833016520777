import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, earthOutline, exitOutline, heartOutline, heartSharp, lockOpenOutline, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, personAddOutline, personOutline, shieldOutline, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Console } from 'console';


interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}
let appPages: AppPage[];
if (new Cookies().get("jwt")) {
  appPages = [
    {
      title: 'Home',
      url: '/Home/',
      iosIcon: earthOutline,
      mdIcon: earthOutline
    },
    {
      title: 'Profile',
      url: '/User/' + new Cookies().get("user id"),
      iosIcon: personOutline,
      mdIcon: personOutline
    },
    {
      title: 'Permissions',
      url: '/Permissions/',
      iosIcon: lockOpenOutline,
      mdIcon: lockOpenOutline
    },
    {
      title: 'Privacy policy',
      url: '/Privacy/',
      iosIcon: shieldOutline,
      mdIcon: shieldOutline
    },
    {
      title: 'Logout',
      url: '/Logout',
      iosIcon: exitOutline,
      mdIcon: exitOutline
    }
  ];
}
else {
  appPages = [
    {
      title: 'Home',
      url: '/Home/',
      iosIcon: earthOutline,
      mdIcon: earthOutline
    },
    {
      title: 'Log in',
      url: '/Login',
      iosIcon: personOutline,
      mdIcon: personOutline
    },
    {
      title: 'Sign up',
      url: '/Signup',
      iosIcon: personAddOutline,
      mdIcon: personAddOutline
    },
    {
      title: 'Permissions',
      url: '/Permissions/',
      iosIcon: lockOpenOutline,
      mdIcon: lockOpenOutline
    },
    {
      title: 'Privacy policy',
      url: '/Privacy/',
      iosIcon: shieldOutline,
      mdIcon: shieldOutline
    }
  ];
}

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Waddup</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} menu="main" autoHide={false}>
                <IonItem button className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="root" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
