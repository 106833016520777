import { IonButton, IonCard, IonItem, IonLabel, IonTextarea, useIonToast } from "@ionic/react"
import { BaseSyntheticEvent, FormEvent, useState } from "react";
import Cookies from "universal-cookie";

interface CommentBoxInterface 
{
    commentLink?: string,
    idParam: string,
    id: string,
    parentId?: string,
    toggleCommenting: Function
}

const CommentBox = ({commentLink, idParam, id, parentId, toggleCommenting} : CommentBoxInterface) => {

    const [enabled, setEnabled] = useState(true);

    const [toast, dissmissToast] = useIonToast();

    const handleComment = (e: BaseSyntheticEvent) => {
        e.preventDefault()
        let formData = new FormData(e.target);
        formData.append("jwt", new Cookies().get("jwt"))
        formData.append(idParam, id);
        // Used for children comments
        if (parentId)
            formData.append("parent id", parentId)
        // Send data to the backend via POST
        setEnabled(false)
        fetch('https://app.deaddev.com/final' + commentLink, {
            method: 'POST',
            mode: 'cors',
            body: formData // body data type must match "Content-Type" header

        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson["success"]) {
                    toggleCommenting()
                }
                else 
                {
                    toast({message: responseJson["reason"], color:"danger", duration:800})
                    setEnabled(true)
                }
            })
            .catch(error => {
                toast({message: "Something went wrong!", color:"danger", duration:800})
                setEnabled(true)
            });
    }

    return <IonCard>
        <form onSubmit={handleComment}>
            <IonItem fill="outline">
                <IonLabel position="stacked">Comment</IonLabel>
                <IonTextarea disabled = {!enabled} name="comment" autoGrow rows={1}/>
            </IonItem>
            <IonButton type="submit" disabled = {!enabled}>Reply</IonButton>
        </form>
    </IonCard>

}
export default CommentBox;