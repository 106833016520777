// TODO: Fix expected bug where modul state is not being handled properly

import { IonCol, IonContent, IonItem, IonRow, IonSkeletonText, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Media } from "../Interfaces/Interfaces";
import Comments from "./Comments";
import { VoteButtons } from "./VoteButtons";
import { PointInfo as PointInfoInterface } from '../Interfaces/Interfaces'
import { LatLng, Map } from 'leaflet'
import VideoPlayer from "./VideoPlayer";
import CommentBox from "./CommentBox";

interface PointInfoProps {
    point_id: number | undefined,
    setModalTitle: Function,
    map: Map | undefined,
    votePrompt?: Function,
    commentPrompt?: Function
}

// NB: I might have fixed it before testing :p (It's about 3am right now)
export const PointInfo = ({ point_id, setModalTitle, map, votePrompt, commentPrompt}: PointInfoProps) => {
    
    const [pointInfo, setPointInfo] = useState<PointInfoInterface>()
    const [pointID, setPointID] = useState<number>(-1);
    const [isCommenting, SetIsCommenting] = useState(false);

    const ToggleCommenting = () => 
    {
        if(commentPrompt) 
        {
            commentPrompt()
            return
        }
        SetIsCommenting(!isCommenting);

    }

    {/* Fetches the information of a point from the api */ }

    useEffect(() => {
        if (point_id && pointID !== point_id) {
            setPointID(point_id)
            const formData = new FormData();
            formData.append("point id", point_id.toString());
            formData.append("jwt", new Cookies().get("jwt"))
            setPointInfo(undefined)
            fetch('https://app.deaddev.com/final/v0.1/point', {
                method: 'POST',
                mode: 'cors',
                body: formData

            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson["success"]) {
                        setPointInfo(responseJson["data"])
                        setModalTitle(responseJson["data"]["Title"])
                        map?.flyTo(new LatLng(responseJson["data"]["Lat"], responseJson["data"]["Lng"]), 18)
                    }
                });
        }

    });

    if (pointInfo) {
        return <>
            {pointInfo["Media"].length === 0 ? null : (
                <IonRow style=
                    {{
                        maxHeight: 230,
                        overflowX: "hidden",
                        overflowY: "hidden"
                    }}>
                    <IonCol>
                        <IonItem >
                            <IonContent scrollY={false} scrollX={true} class="ion-text-nowrap" style=
                                {{
                                    height: pointInfo["Media"].length === 0 ? null : 220,
                                    "--background": "transparent"
                                }}>
                                {
                                    pointInfo["Media"].map((media: Media, index: number) => {
                                        console.log(media);
                                        return !media["Is Preview"] ? <img style=
                                            {{
                                                height: 200,
                                                width: "fit-content",
                                                display: "inline-block",
                                                padding: 10,
                                                backgroundColor: "#00000000"
                                            }} src={"https://final.deaddev.com/media/" + media["File name"]}></img>
                                            : <VideoPlayer media={media}/>
                                    })}
                            </IonContent>
                        </IonItem>
                    </IonCol>
                </IonRow>
            )}
            {pointInfo.Description === null ? <></> : (
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonText>
                                {pointInfo.Description}
                            </IonText>
                        </IonItem>
                    </IonCol>
                </IonRow>)}
            <VoteButtons {...{ reputation: pointInfo.Reputation, voteLink: "point/vote",
            shareLink: "https://final.deaddev.com/Home/Point/" + pointID, elementKey: "point id", elementValue: pointInfo.ID, voted: pointInfo.Vote,
            prompt: votePrompt, toggleCommenting: ToggleCommenting }} />
            {isCommenting? <CommentBox commentLink="/v0.1/point/comment" idParam="point id" id={pointID.toString()} toggleCommenting={ToggleCommenting}/> : null}
            
        </>
    }
    else {
        return <>
            <IonRow style=
                {{
                    maxHeight: 230,
                    overflowX: "hidden",
                    overflowY: "hidden"
                }}>
                <IonCol>
                    <IonItem >
                        <IonContent scrollY={false} scrollX={true} class="ion-text-nowrap" style=
                            {{
                                height: 220,
                                "--background": "transparent"
                            }}>

                            <IonSkeletonText animated style={{ width: '130px', height: '200px', display: 'inline-block', margin: 10 }} />
                            <IonSkeletonText animated style={{ width: '380px', height: '200px', display: 'inline-block', margin: 10 }} />
                            <IonSkeletonText animated style={{ width: '130px', height: '200px', display: 'inline-block', margin: 10 }} />
                            <IonSkeletonText animated style={{ width: '380px', height: '200px', display: 'inline-block', margin: 10 }} />
                        </IonContent>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonSkeletonText animated style={{ width: '100%', height: '80px' }} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonSkeletonText animated style={{ width: '90px', height: '20px' }} />
                    </IonItem>
                </IonCol>
            </IonRow>

        </>
    }
}
