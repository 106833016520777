import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonPopover } from '@ionic/react';
import { settingsOutline } from 'ionicons/icons';
import { Component, CSSProperties, useState } from 'react';
import { start } from 'repl';
import { Media, Resolution } from '../Interfaces/Interfaces'

interface VideoPlayerInterface {
    media: Media
}

const VideoPlayer = ({ media }: VideoPlayerInterface) => {

    const [videoResolution, setVideoResolution] = useState<string>(media["Resolutions"][0]["Resolution"])
    const [videoSource, setVideoSource] = useState<string>(media["Resolutions"][0]["File name"])
    return <div style=
        {{
            position: 'relative',
            display: "inline-block",
            width: "fit-content",
        }} >
            <div >
        <video style=
            {{
                height: 200,
                width: "fit-content",
                display: "inline-block",
                padding: 10,
                backgroundColor: "#00000000"
            }} src={"https://final.deaddev.com/media/" + videoSource} controls>
        </video>

        <IonButton color="dark" id={"video-" + media["Video ID"]} style={{
            position : "absolute",
            top : 10,
            right :15 

        }}> {videoResolution}
        </IonButton>
        <IonPopover dismiss-on-select 
        backdrop-dismiss ={true}
         side={'left'} 
         size={'auto'}
         trigger={"video-" + media["Video ID"]} >

                {
                    media["Resolutions"].map((value: Resolution) => {
                        return <IonItem button detail={false} 
                        onClick={() => {
                            setVideoResolution(value["Resolution"])
                            setVideoSource(value["File name"])
                        }}>
                            {value["Resolution"]  }
                        </IonItem>
                    })}
            
        </IonPopover>
        </div>


    </div>
}; export default VideoPlayer