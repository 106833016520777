import 'react-leaflet';
import React, { useState } from 'react';
import {
  caretUpOutline,
  caretDownOutline
} from 'ionicons/icons';
import {
  IonContent,
  IonItem,
  IonAvatar,
  IonLabel,
  IonSkeletonText,
  IonListHeader,
  IonIcon,
  IonThumbnail,
  IonList,
  IonButton,
  IonPage,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonMenuButton,
} from '@ionic/react';
import { call } from 'ionicons/icons';

const Permissions: React.FC = () => {
  return <IonPage>
    {/* Not important header stuff */}
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Waddup</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="ion-text-left ion-padding">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Permissions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonList>
        <IonItem>
          1. What are guests allowed to do:
        </IonItem>
        <IonItem class="item-text-wrap">
          Guests are allowed to surf the app without the access to add points, add comments or vote on othe posts/comments.
        </IonItem>
        <IonItem class="item-text-wrap">
          2. What are logged in users are allowed to do?
        </IonItem>
        <IonItem class="item-text-wrap">
          2.1. To vote:
        </IonItem>
        <IonItem class="item-text-wrap">
          the account must be older then 7 days old.
        </IonItem>      <IonItem class="item-text-wrap">
          2.2. To comment:
        </IonItem>
        <IonItem class="item-text-wrap">
          the account must be older then 7 days old.
        </IonItem>
        <IonItem class="item-text-wrap">
          2.3. To Add a point:
        </IonItem>
        <IonItem class="item-text-wrap">
          the user must have a reputation of over 200.
        </IonItem>
        <IonItem class="item-text-wrap">
          3. How to gain reputation?
        </IonItem>
        <IonItem class="item-text-wrap">
          The user can add comments to a posted point and via the upvotes, the user gains the reputation.
        </IonItem>


      </IonList>
    </IonContent>
  </IonPage>
};

export default Permissions;
