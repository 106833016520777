import './PointInfoSkeleton.css';
import 'react-leaflet';
import React, { useState } from 'react';
import {
    caretUpOutline,
    caretDownOutline
} from 'ionicons/icons';
import {
    IonContent,
    IonItem,
    IonAvatar,
    IonLabel,
    IonSkeletonText,
    IonListHeader,
    IonIcon,
    IonThumbnail,
    IonList,
    IonButton,
    IonPage,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonMenuButton,
} from '@ionic/react';
import { call } from 'ionicons/icons';

const Privacy: React.FC = () => {
    return <IonPage>
        {/* Not important header stuff */}
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>Waddup</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent class="ion-text-left ion-padding">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Privacy Policy</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonText>
                <h3>1. What information do we collect?</h3>
                <ul>
                    <li><b>Account information: </b>When you register a Waddup account, we collect your user name and email
                        address. We use this information for account identification and email communication about the account
                        status.<br /><br /></li>
                    <li><b>Device information: </b>When you install the Waddup mobile app to your mobile device, we collect
                        device ID/name and model. We use this information for device identification.<br /><br /></li>
                    <li><b>Location information: </b>When you run the Waddup mobile app in your device, we collect location data
                        through GPS, wifi, or cellular triangulation. We use this information to provide location tracking service
                        on our web site. We maintain this data only so long as is reasonable to provide our service. Out-of-date
                        data is removed from our database. <br /><br /></li>
                    <li><b>Log files: </b>Our server automatically gathers some anonymous information about visitors, including IP
                        addresses, browser type, language, and the times and dates of web page visits. The data collected does not
                        include personally identifiable information and is used for server performance analysis and troubleshooting
                        purpose.<br /><br /></li>
                    <li><b>Cookies: </b>We use cookies to keep you logged in and save your visit preferences.<br /><br /></li>
                </ul>
            </IonText>
            <IonText>
                <h3>2. How long do we retain your information?</h3>
                We will retain your information for as long as is reasonable to provide our service. Out-of-date information will be
                removed from our database. We will retain and use your information to the extent necessary to comply with our legal
                obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes,
                and enforce our legal agreements and policies.
                <br />
                <br />
                We will also retain log files for internal analysis purposes. Log files are generally retained for a shorter period
                of time, except when this data is used to strengthen the security or to improve the functionality of our service, or
                we are legally obligated to retain this data for longer time periods.
                <br />
                <br />
            </IonText>
            <IonText>
                <h3>3. Where do we store your information?</h3>
                We host our database and servers in london.
                <br />
                <br />
            </IonText>
            <IonText>
                <h3>4. How do we protect your information?</h3>
                We have implemented procedures to safeguard and secure the information we collect from loss, misuse, unauthorized
                access, disclosure, alteration, and destruction and to help maintain data accuracy and ensure that your personal
                data is used appropriately.
                <ul>
                    <li>We protect your data in your device. The Waddup mobile app collects location data from your tracked
                        device. We encrypt your location data when transmitting from your device to our server. We then delete this
                        data from your device once data transmission completes.</li>
                    <li>We protect your data on-line. Data access is protected by an account authentication process. Only account
                        holder who knows the account credential can access to your own data in your account. Other users cannot
                        access your data unless you have opted in location sharing.</li>
                    <li>We protect your data off-line. Your account and location data are stored in our secured database. Only
                        employee who needs the information to perform a specific job is granted access. The server in which we store
                        our database is hosted with Amazon Web Service in a secure environment.</li>
                </ul>
            </IonText>
            <IonText>
                <h3>5. Do we share your information to outside parties?</h3>
                We do not share your personal data with third parties, other than as necessary to fulfill our services. We do not
                sell your personal data to any third parties. We may be required to disclose an individual’s personal data in
                response to a lawful request by public authorities, including to meet national security or law enforcement
                requirements. For example, we may share information to respond to a court order, subpoena, or request from a law
                enforcement agency.
                <br />
                <br />
            </IonText>
            <IonText>
                <h3>6. Contact us</h3>

                If you have questions or concerns regarding this Privacy Policy, you should first email us at <a
                    href="mailto:privacy@deaddev.com">privacy@deaddev.com</a>.
                <br />
                <br />
            </IonText>
            <IonText>
                <h3>7. How often do we update this Privacy Policy?</h3>
                We may modify this Privacy Policy from time to time. Please see the revised date at the top of this page to see when
                this Privacy Policy was last revised.
            </IonText>
        </IonContent>
    </IonPage>
};

export default Privacy;
