import { IonButton, IonCard, IonCol, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonSkeletonText, IonText, useIonToast } from '@ionic/react';
import { Comment } from '../Interfaces/Interfaces'
import React, { useEffect, useState } from 'react'
import { arrowDown, arrowUndoOutline, arrowUp, ellipsisVertical, ellipsisVerticalCircle, shareOutline, shareSocialOutline } from 'ionicons/icons';
import Cookies from 'universal-cookie';

interface VoteButtonProps 
{
    reputation: number,
    voteLink: string,
    shareLink: string,
    elementKey: string,
    elementValue: string,
    voted: number,
    prompt?: Function,
    toggleCommenting: Function
}



export const VoteButtons = ({ reputation, voteLink, shareLink, elementKey, elementValue, voted, prompt, toggleCommenting } : VoteButtonProps) =>
{
    const [present, dismiss] = useIonToast();
    
    const [vote, setVote] = useState<number>(voted);

    // This is to fix the incorrect reputation being shown to the user
    if (voted) 
    {
        reputation -= voted;   
    }
    const [shownReputation, setShownReputation] = useState<number>(reputation + (voted? voted : 0));
    
    

    // TODO fix already voted points not updating reputation correctly
    
    const ShareLink = (link: string) =>
    {
        navigator.clipboard.writeText(link)
        present({
            message: 'Copied link!',
            duration: 1000
        })
    }
    
    const Vote = (sendVote: number) => 
    {
        let actualVote = sendVote === vote ? 0 : sendVote
        let voteData = new FormData();
        voteData.append("jwt", new Cookies().get("jwt"))
        voteData.append("vote", actualVote.toString())
        voteData.append(elementKey, elementValue)
        fetch('https://app.deaddev.com/final/v0.1/' + voteLink, {
                method: 'POST',
                mode: 'cors',
                body: voteData

            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson["success"]) {
                        setVote(actualVote);
                        setShownReputation(reputation += actualVote);
                    }
                    else 
                    {
                        // TODO: Show login prompt
                    }
                }
                );
    }

    return <IonRow>
        <IonCol>
            <IonItem>   

            <IonButton style={{width:"fit-content"}} fill="clear" color={vote === 1 ? "primary" : "dark"} onClick={()=>{prompt ? prompt() : Vote(1)}}>
                <IonIcon icon={arrowUp} style={{padding: "0px 0px"}}/>
            </IonButton>
                <IonText style={{height: "fill", padding:"3px 0"}}>{shownReputation}</IonText>
            <IonButton style={{width:"fit-content"}} fill="clear" color={vote === -1 ? "primary" : "dark"}onClick={()=>{prompt ? prompt() : Vote(-1)}}>
                <IonIcon icon={arrowDown} style={{padding: "0px 0px"}}/>
            </IonButton>
            <IonButton fill="clear" color="dark" onClick={() => {if(toggleCommenting) toggleCommenting()}}>
                <IonIcon icon={arrowUndoOutline} slot="start"></IonIcon>
                <IonText>Reply</IonText>
            </IonButton>
            <IonButton id="more-button" fill="clear" color="dark" onClick={() => {ShareLink(shareLink)}}>
                <IonIcon icon={shareSocialOutline} slot="start"></IonIcon>
                <IonText>Share</IonText>
            </IonButton>
            </IonItem>
            
        </IonCol>
        </IonRow>
}