import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { withRouter } from 'react-router-dom';
import { personCircle } from 'ionicons/icons';
import './Auth.css';
import React, { FormEvent, useState } from 'react';
import Cookies from 'universal-cookie';

const Login: React.FC = () => {
  const [userId, setUserId] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loginResult, setLoginResult] = useState<string>();
  const [resultColor, setResultColor] = useState<string>();
  const colors = {
    "error": "#FF6666",
    "normal": "#DDDDDD"
  };


  const handleLogin = (e: FormEvent) => {
    e.preventDefault()
    setResultColor(colors["normal"]);
    setLoginResult("Logging in...");

    const formData = new FormData();
    if (userId !== undefined && password !== undefined) {
      formData.append('username', userId);
      formData.append('password', password);
    }
    // Send data to the backend via POST
    fetch('https://app.deaddev.com/final/v0.1/auth/login', {
      method: 'POST',
      mode: 'cors',
      body: formData // body data type must match "Content-Type" header

    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson["success"]) {
          setResultColor(colors["normal"]);
          setLoginResult("Logged in, redirecting...");
          const cookies = new Cookies();
          var date = new Date();
          var year = date.getFullYear();
          var month = date.getMonth();
          var day = date.getDate();
          var expiry = new Date(year + 1, month, day);
          cookies.set('jwt', responseJson["jwt"], { path: '/', expires: expiry });
          cookies.set('user id', userId, { path: '/', expires: expiry })
          setTimeout(() => {
            window.location.href = '/Home'; //relative to domain
          }, 500);
        }
        else {
          setResultColor(colors["error"]);
          setLoginResult(responseJson["reason"]);
        }
      })
      .catch(error => {
        setResultColor(colors["error"]);
        setLoginResult("Login failed, network error");
      });
  }
  return <IonPage>
    {/* Not important header stuff */}
    <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Waddup</IonTitle>
        </IonToolbar>
      </IonHeader>
    <IonContent class="ion-text-center">
      <IonCard id="LoginCard">

        <IonHeader>
          <IonToolbar>
            <IonTitle>Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid style={{
          padding: 35
        }}>

          <IonRow>
            <IonCol>
              <IonIcon
                style={{ fontSize: "70px" }}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p style={{ fontSize: "medium", color: resultColor }}
                id="LoginResult">
                {loginResult}
              </p>
            </IonCol>
          </IonRow>
          <form onSubmit={handleLogin}>

            <IonRow>
              <IonCol>
                <IonItem fill="outline">
                  <IonLabel position="floating">Username</IonLabel>
                  <IonInput
                    type="text"
                    inputMode="text"
                    debounce={8}
                    required
                    value={userId}
                    onIonChange={(e) => setUserId(e.detail.value!)}
                  >
                  </IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem fill="outline">
                  <IonLabel position="floating"> Password</IonLabel>
                  <IonInput
                    type="password"
                    minlength={8}
                    debounce={8}
                    required
                    value={password}
                    onIonChange={(e) => setPassword(e.detail.value!)}
                  >
                  </IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <p style={{ fontSize: "small" }}>
                  By clicking LOGIN you agree to our <a href="/Privacy/">Policy</a>
                </p>
                {/* This is a hack to fix the form not submitting on enter */}
                <input type="submit" style={{ height: 0, border: 0, display: "none" }} />
                <IonButton type="submit" expand="block">
                  Login
                </IonButton>
                <p style={{ fontSize: "medium" }}>
                  Don't have an account? <a href="/Signup">Sign up</a>
                </p>
                <p style={{ fontSize: "medium" }}>
                  <a href="/Forgot">Forgot password</a>
                </p>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </IonContent>
  </IonPage>

}
export default withRouter(Login)