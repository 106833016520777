import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { withRouter } from 'react-router-dom';
import { personCircle } from 'ionicons/icons';
import './Auth.css';
import React, { FormEvent, useState } from 'react';
import Cookies from 'universal-cookie';

const Forgot: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const [comunicationResult, setComunicationResult] = useState<string>();
  const [resultColor, setResultColor] = useState<string>();
  const [submitted, setSubmitted] = useState(false);

  const colors = {
    "error": "#FF6666",
    "normal": "#DDDDDD"
  };


  const handleComunication = (e: FormEvent) => {
    e.preventDefault()

    const formData = new FormData();
    if (!email) {
      return false
    }
    formData.append('email', email);

    // Send data to the backend via POST
    fetch('https://app.deaddev.com/final/v0.1/auth/request/recovery', {
      method: 'POST',
      mode: 'cors',
      body: formData // body data type must match "Content-Type" header

    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson["success"]) {
          setResultColor(colors["normal"]);
          setComunicationResult(undefined);
          setSubmitted(true)
          const cookies = new Cookies();
          cookies.set('jwt', responseJson["jwt"], { path: '/' });
          setTimeout(() => {
            console.log("request sent")
          }, 500);
        }
        else {
          setResultColor(colors["error"]);
          setComunicationResult(responseJson["reason"]);
        }
      })
      .catch(error => {
        setResultColor(colors["error"]);
        setComunicationResult("Connection failed, network error");
      });
  }
  return <IonPage>
    {/* Not important header stuff */}
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Waddup</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="ion-text-center">
      <IonCard id="ForgotCard">

        <IonHeader>
          <IonToolbar>
            <IonTitle>Forgot password</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid style={{
          padding: 35
        }}>
          {submitted ? <>
            <IonRow>
              <IonCol>
                <IonIcon
                  style={{ fontSize: "70px" }}
                  icon={personCircle}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <p style={{ fontSize: "medium", color: resultColor }}
                  id="comunicationResult">
                  {comunicationResult}
                </p>
              </IonCol>
            </IonRow>
            <IonRow >
              <IonCol >
                <IonText color="dark" style={{fontSize: 16}}>
                  Kindly check your email for the recovery steps

                </IonText>
              </IonCol>
            </IonRow>
            <IonRow><IonCol><br></br></IonCol></IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  Done with the email? <a href="/Login">Log in</a>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  Don't have an account? <a href="/Signup">Sign up</a>
                </IonText>
              </IonCol>
            </IonRow>
          </> : <>
            <IonRow>
              <IonCol>
                <IonIcon
                  style={{ fontSize: "70px" }}
                  icon={personCircle}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <p style={{ fontSize: "medium", color: resultColor }}
                  id="comunicationResult">
                  {comunicationResult}
                </p>
              </IonCol>
            </IonRow>
            <form onSubmit={handleComunication}>

              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonText class="ion-text-center">
                      Enter your email and we will send you the steps to recover your account
                    </IonText>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow><IonCol><br/></IonCol></IonRow>
              <IonRow>
                <IonCol>
                  <IonItem fill="outline">
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      type="email"
                      inputMode="email"
                      debounce={8}
                      required
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value!)}
                    >
                    </IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {/* This is a hack to fix the form not submitting on enter */}
                  <input type="submit" style={{ height: 0, border: 0, display: "none" }} />
                  <IonButton type="submit" expand="block">
                    Submit
                  </IonButton>
                  <p style={{ fontSize: "medium" }}>
                    Don't have an account? <a href="/Signup">Sign up</a>
                  </p>
                </IonCol>
              </IonRow>
            </form>
          </>}
        </IonGrid>

      </IonCard>
    </IonContent>
  </IonPage>

}
export default withRouter(Forgot)