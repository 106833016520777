import {
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';

import { BrowserRouter, Redirect, useLocation, useParams, withRouter } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, personCircle, pin, trashOutline, trashSharp, walk, warning, warningOutline, warningSharp, wifi, wine } from 'ionicons/icons';
import './Auth.css';
import React, { FormEvent, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { render } from 'react-dom';
import { Swiper, SwiperSlide } from "swiper/react";

const Verify: React.FC = () => {
    const { id, email, code } = useParams<{ email: string; id: string; code: string; }>();
    const [submitVerification, setSubmitVerification] = useState<string>();
    const [resultColor, setResultColor] = useState<string>();
    const [pin, setPin] = useState<string>(code);
    const colors = {
        "error": "#FF6666",
        "normal": "#DDDDDD"
    };

    const cookies = new Cookies();
    if (!id || !email) {
        window.location.href = '/Signup'; //relative to domain
    }

    const HandleVerification = (e?: FormEvent) => {
        e?.preventDefault()
        setResultColor(colors["normal"]);
        setSubmitVerification("Verifying...");

        const VerificationData = new FormData();
        if (pin !== undefined && id !== undefined) {
            VerificationData.append('custom user id', id);
            VerificationData.append('pin', pin);
        }

        // Send data to the backend via POST
        fetch('https://app.deaddev.com/final/v0.1/auth/signup/verify', {
            method: 'POST',
            mode: 'cors',
            body: VerificationData // body data type must match "Content-Type" header

        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson["success"]) {
                    setResultColor(colors["normal"]);
                    setSubmitVerification("Verified, redirecting...");
                    const cookies = new Cookies();
                    cookies.set('jwt', responseJson["jwt"], { path: '/' });
                    setTimeout(() => {
                        window.location.href = '/Home'; //relative to domain
                    }, 500);
                }
                else {
                    setResultColor(colors["error"]);
                    setSubmitVerification(responseJson["reason"]);
                }
            })
            .catch(error => {
                setResultColor(colors["error"]);
                setSubmitVerification("Verification failed, network error")
            })
    }

    const [autoVerify, setAutoVerify] = useState<boolean>(code ? true : false)
    useEffect(() => {
        if (autoVerify) {
            setAutoVerify(false);
            setPin(code);
            HandleVerification();
        }
    });


    return <IonPage>
        {/* Not important header stuff */}
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>Waddup</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent class="ion-text-center">
            <IonCard id="VerificationCard">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Verify your acount</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid style={{ padding: 24 }}>

                    <IonRow>
                        <IonCol>
                            <IonIcon
                                style={{ fontSize: "70px" }}
                                icon={personCircle}
                            />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <p>We've sent an email to <a href={"mailto:" + email}>{email}</a> with the pin to verify your account.<br />
                                It might be in your spam folder.</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>

                        <IonCol>
                            <p style={{ fontSize: "medium", color: resultColor }}
                                id="SubmitResult">
                                {submitVerification}
                            </p>
                        </IonCol>
                    </IonRow>
                    <form onSubmit={HandleVerification}>
                        <IonRow>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="fixed">User Id</IonLabel>
                                    <IonText style={{ opacity: 0.6 }}> {id}
                                    </IonText>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem fill="outline">
                                    <IonLabel position="fixed">Code</IonLabel>
                                    <IonInput
                                        type="number"
                                        debounce={8}
                                        required
                                        value={pin}
                                        onIonChange={(e) => setPin(e.detail.value!)}
                                    >
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p style={{ fontSize: "small" }}>
                                    Didn't receive a verification code? <a href="/Signup">Send again</a>
                                </p>
                                <IonButton type="submit" expand="block">
                                    Verify
                                </IonButton>
                                <p style={{ fontSize: "medium" }}>
                                    <a href="/Home">Take a tour as a Guest</a>
                                </p>
                                {/* This is a hack to fix the form not submitting on enter */}
                                <input type="submit" style={{ visibility: 'hidden' }} />

                            </IonCol>
                        </IonRow>
                    </form>
                </IonGrid>
            </IonCard>
        </IonContent>
    </IonPage>
}
export default withRouter(Verify)